<template>
  <div class="w-100 b-search-rounded shadow-sm d-flex align-items-center">
    <!-- <form @submit.prevent> -->
    <div class="flex-fill b-input-box d-flex align-items-stretch pr-2">
      <input type="text" class='b-input flex-fill' :value="value" placeholder="無輸入，搜尋附近"
        @input="$emit('input', $event.target.value)">
      <div class="d-flex align-items-center justify-content-center" style="width: 40px"
        @click="$emit('input', '')">
        <font-awesome-icon :icon="['fas', 'times']" />
      </div>
    </div>

    <slot name='searchbtn' v-bind='{searchbtn:search}'>
      <button class='b-search-btn' @click.prevent='search()'>
        <font-awesome-icon :icon="['fas', 'search']" class='b-times' />
      </button>
    </slot>

    <!-- </form> -->
  </div>
</template>

<script>
  import "@/style/SearchBtn.scss";
  import moment from "moment";
  const listMethods = {

  }
  let nameComputed = {}
  export default {
    name: 'Searchbtn',
    data() {
      const today = moment();
      return {};
    },
    methods: listMethods,
    computed: nameComputed,
    props: {
      search: Function,
      value: String,
    },
  };

</script>
